@font-face {
  font-family: "NanumC";
  src: url("../Exam/assets/fonts/Nanum1.ttf");
}

.know-more {
  font-size: smaller;
  pointer-events: none;
}
.card-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 560px;
  font-size: 16px;
  background-color: white;
  padding: 20px;
  border: 1px solid black;
  border-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.line-svg {
  width: 100%;
  stroke-dasharray: 2.5;
  stroke-dashoffset: 2.9;
  position: absolute;
  bottom: 0;
  left: 0;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-start {
  align-items: flex-start;
}
.hr-1 {
  margin-bottom: 10px;
  font-size: large;
  color: #4bb7e6;
  font-weight: 700;
  /*text-shadow: rgb(53, 50, 74) 1px 0 10px;*/
}
.hr-1-title {
  font-weight: 500;
}
.hr-2 {
  margin-bottom: 0px;
  font-size: x-large; /*medium;*/
  font-weight: 800;
  color: #1761eb;
  text-shadow: rgb(217, 215, 231) 1px 0 10px;
}
.hr-21 {
  margin-bottom: 0px;
  font-size: x-large; /*medium;*/
  font-weight: 400;
  color: #06040e;
  text-shadow: rgb(217, 215, 231) 1px 0 10px;
}
.hr-22 {
  margin-bottom: 0px;
  font-size: x-large; /*medium;*/
  font-weight: 800;
  color: #06040e;
  text-shadow: rgb(217, 215, 231) 1px 0 10px;
}
.hr-32 {
  margin-bottom: 0px;
  font-size:  xx-large; /*medium;*/
  font-weight: 800;
  color: #06040e;
  text-shadow: rgb(217, 215, 231) 1px 0 10px;
}
.hr-3 {
  margin-bottom: 10px;
  font-size: large; /*medium;*/
  font-weight: 700;
  color: #b41b4e;
}
.hr-4 {
  margin-bottom: 10px;
  font-size: medium; /*medium;*/
  font-weight: 700;
  color: #0c051f;
}
.hr-5 {
  margin-bottom: 10px;
  font-size: small; /*medium;*/
  font-weight: 700;
  color: #0c051f;
}
.meta-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.title {
  text-transform: uppercase;
  font-size: small;
  font-weight: 700;
}
.subtitle {
  font-size: small;
  font-weight: 500;
}
.meta-section {
  padding-top: 40px;
}

.meta {
  margin-top: 10px;
}
