

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.App {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(4, 37, 70);
}

.phone-container {
  width: 100%;
  max-width: 576px;
  min-width: 260px;
  height: 100%;
  background-image: url("./constants/assets/iphone-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}


.copyright {
  position: fixed;
  bottom: 10px;
  right: 10px;
  box-shadow: var(--shadow-elevation-medium);
  padding: 5px 10px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: black;
  z-index: 99;
}

.debug {
  position: fixed;
  top: 10px;
  right: 10px;
  box-shadow: var(--shadow-elevation-medium);
  padding: 5px 10px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: black;
  z-index: 99;
}

.editor {
  position: fixed;
  bottom: 10px;
  left: -5px;
  box-shadow: var(--shadow-elevation-medium);
  padding: 5px 10px;
  background-color: rgb(223, 24, 57);
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: black;
  z-index: 99;
}

.developer {
  color: rgb(4, 37, 70);
  margin-right: 10px;
  font-weight: 500;
}


