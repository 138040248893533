a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.wrapper {
  position: relative;
  top: 45px;
  display: flex;
  justify-content: center;
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}



.deck {
  position: absolute;
  top: 95px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deck > div {
  touch-action: none;
  background-color: white;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}



.carousel > div {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel > img {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
